body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

input::-moz-focus-inner {
  border: 0;
}

a {
  outline: 0;
  text-decoration: none;
}

/* overwrite mui-select styles */
.css-1wy0on6 {
  cursor: pointer !important;
  margin-top: -10px;
  height: 47px;
}

.css-1wy0on6 > div {
  color: #777777;
}

.css-d8oujb {
  background-color: #777777 !important;
}

.css-1wy0on6 > div:hover {
  color: #555555;
}
/* end of overwrite mui-select styles */

/* overwrite carousel styles */
.carousel ul.thumbs {
  padding-left: 0 !important;
}

.carousel p.legend {
  bottom: -15px !important;
  width: 100% !important;
  margin-left: 0 !important;
  left: 0 !important;
  border-radius: 0 !important;
}

.carousel .thumbs-wrapper {
  margin: 10px 0 !important;
}

.carousel .thumbs-wrapper .thumbs .thumb {
  cursor: pointer;
}
/* end of overwrite carousel styles */

/* overwrite react-slider styles */
.slick-slider {
  width: calc(100% - 80px);
  margin: 0 auto !important;
}

.arrowSlider {
  width: 32px;
  z-index: 900;
}

.arrowSlider:before {
  font-size: 36px !important;
  color: #555;
}

.arrowSlider:before:hover {
  color: #222;
}

.slider-loader {
  position: absolute;
  width: 100%;
  text-align: center;
  padding-top: 10%;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 2em;
}

.active-page {
  background-color: green !important;
  color: #fff !important;
  cursor: default !important;
}

.pager {
  padding: 10px 16px 28px;
  text-align: center;
}

.pager button {
  width: 52px;
  padding: 0px;
  margin: 0 5px 10px;
  height: 30px;
  cursor: pointer;
  border: 1px solid #aaa;
  border-radius: 3px;
  font-weight: 600;
  color: #555;
  background-color: #eee;
}

.pager button:hover {
  background-color: #bbb;
}

.pager button.active-page:hover {
  background-color: green;
}

@media (max-width: 800px) {
  .arrowSlider {
    top: 25px !important;
  }

  .slick-slider {
    width: 100%;
  }

  .slick-slider .slick-list {
    padding-top: 30px;
  }

  .slick-slider .slick-prev {
    left: 14px !important;
  }

  .slick-slider .slick-next {
    right: 14px !important;
  }
}
/* end of overwrite react-slider styles */
